<template>
  <div>
    <NCard
      size="small"
      title="任务内容"
      :segmented="{ content: 'hard' }"
    >
      <template #header-extra>
        <span class="primary-color">
          建议时长：{{ totalRecommendMins }} min
        </span>
      </template>
      <template #default>
        <!--
        <TeachingUpload
          :isMaterialOrCourse="isMaterialOrCourse"
          :readOnly="taskData.readOnly"
          :teachingArr="taskData.teachingArr"
        />
        -->
        <RenameDataList
          v-show="taskData.knowledgeArr.length > 0"
          :readOnly="taskData.readOnly"
          nameProp="name"
          keyProp="id"
          :list="taskData.knowledgeArr"
          @item-del="updateSkillArr('del', $event)"
        />
        <div v-if="!taskData.readOnly" style="text-align: center;">
          <NButton size="small" @click="handleAddKnowledgeClick">
            <template #icon>
              <NIcon><AddRound /></NIcon>
            </template>
            添加实操
          </NButton>
        </div>
      </template>
    </NCard>

    <AddKnowledgeModal
      ref="addKnowledgeModalRef"
      @knowledge-arr-add="handleKnowledgeArrAdd"
    />
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { NCard, NButton, NIcon } from 'naive-ui';
  import { AddRound } from '@vicons/material';

  import TeachingUpload from './TeachingUpload.vue';
  import RenameDataList from './RenameDataList.vue';
  import AddKnowledgeModal from '@/components/AddKnowledgeModal/AddKnowledgeModal.vue';

  import { resourceTypeValMap, generateResourceItem } from '../generate-data.js';

  const props = defineProps({
    addedKnowledgeIdArr: {
      type: Array,
      default: () => []
    },
    taskData: {
      required: true,
      type: Object
    },
    skillArr: {
      type: Array,
      default: () => []
    },
    idSkillObjMap: {
      type: Object,
      default: () => {}
    },
    isMaterialOrCourse: {
      type: String,
      default: 'material'
    }
  });

  const addKnowledgeModalRef = ref(null);
  const totalRecommendMins = computed(() => {
    let total = 0;
    props.taskData.knowledgeArr.forEach(({ recommendMins }) => {
      total += recommendMins;
    });
    return Math.round(total);
  });
  function handleAddKnowledgeClick() {
    addKnowledgeModalRef.value.openModal({ filterIds: props.addedKnowledgeIdArr });
  }
  function handleKnowledgeArrAdd(arr) {
    props.taskData.knowledgeArr.push(
      ...arr.map(({ id, title, recommendMins, skillArr }) => generateResourceItem({
        type: resourceTypeValMap.KNOWLEDGE,
        id,
        name: title,
        recommendMins,
        skillArr
      }))
    );
    arr.forEach(({ skillArr }) => {
      updateSkillArr('add', skillArr);
    });
  }

  function updateSkillArr(oper, skillArr) {
    const skillArrLink = props.skillArr;
    const idSkillObjMapLink = props.idSkillObjMap;
    skillArr.forEach(item => {
      const srcItem = idSkillObjMapLink[item.id];
      if (srcItem) {
        const srcNumScore = parseFloat(srcItem.score) || 0;
        const numScore = parseFloat(item.score) || 0;
        let dstNumScore = 0;
        switch (oper) {
          case 'add':
            dstNumScore = Math.round((srcNumScore + numScore) * 10) / 10;
            srcItem.score = dstNumScore;
            break;
          case 'del':
            dstNumScore = Math.round((srcNumScore - numScore) * 10) / 10;
            if (dstNumScore > 0) {
              srcItem.score = dstNumScore;
            } else {
              delete idSkillObjMapLink[item.id];
              const index = skillArrLink.indexOf(srcItem);
              skillArrLink.splice(index, 1);
            }
            break;
        }
      } else {
        skillArrLink.push(item);
        idSkillObjMapLink[item.id] = item;
      }
    });
  }
</script>